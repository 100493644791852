import React from 'react'
import UpcomingEventTimer from './UpcomingEventTimer'
import EventSidebar from './EventSidebar'
import details from '../../assets/images/events/events-details.jpg'

const EventDetailsContent = () => {
    return (
        <section className="events-details-area pb-100">
            <div className="events-details-image">
                <img src={details} alt="details" />

                <UpcomingEventTimer />
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="events-details-header">
                            <ul>
                                <li><i className='bx bx-calendar'></i>Thur, 1 Sep, 2022</li>
                                <li><i className='bx bx-map'></i>East Sussex National, Sussex, UK</li>
                                <li><i className='bx bx-time'></i>12.00PM</li>
                            </ul>
                        </div>

                        <div className="events-details-location">
                            <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2514.0389801016368!2d0.09759271564639442!3d50.94149215974195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1653600600097!5m2!1sen!2suk"
                            width='600'
                            height='450'
                            style={{ border: 0 }}                        //<-------
                            allowFullscreen=''                           //<-------
                            loading='lazy'
                            referrerPolicy='no-referrer-when-downgrade'  //<-------
                            ></iframe>
                         </div>

                        <div className="events-details-desc">
                            <h3>About The Event</h3>
                            <p>Beginners Mac Course.</p>
                            <p>Switched to Mac and all feels a little bit foreign?</p>
                            <h3>Where the event?</h3>
                            <p>This 1 day event will be held at the prestigous East Sussex National Golf Course near Uckfield in East Sussex</p>
                            <h3>Who this event is for?</h3>
                            <p>If you recently switched to an Apple Mac or maybe your workplace are using Macs and you require more basic knowledge then this is a good starting course, we go over navigating your way around teh Mac. The common Gotchas that trip most new users up as well some basic housekeeping.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <EventSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventDetailsContent